// Funktion zum Aktivieren/Deaktivieren eines Buttons und das Hinzufügen/Entfernen der Klasse im HTML
function toggleActiveButton (buttonId, toggleFunction, htmlClass) {
  const button = document.getElementById(buttonId)
  const html = document.documentElement // Zugriff auf den <html> Tag

  // Toggle den Button Status
  button.classList.toggle('active')

  // Wenn der Button aktiv ist, füge die HTML-Klasse hinzu, sonst entferne sie
  if (button.classList.contains('active')) {
    html.classList.add(htmlClass)
    // eslint-disable-next-line no-undef
    localStorage.setItem(htmlClass, 'true') // Speichere die Einstellung
  } else {
    html.classList.remove(htmlClass)
    // eslint-disable-next-line no-undef
    localStorage.removeItem(htmlClass) // Entferne die Einstellung
  }

  // Führe die Funktion für die Barrierefreiheit aus
  toggleFunction() // Diese Funktion sollte korrekt aufgerufen werden
}

function toggleLargeText () {
  const html = document.documentElement
  html.classList.toggle('large-text')
  if (html.classList.contains('large-text')) {
    // eslint-disable-next-line no-undef
    localStorage.setItem('large-text', 'true')
  } else {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('large-text')
  }
}

// Funktionen zum Anpassen der Barrierefreiheit
function toggleContrast (type) {
  const html = document.documentElement
  html.classList.toggle('high-contrast')
  if (html.classList.contains('high-contrast')) {
    // eslint-disable-next-line no-undef
    localStorage.setItem('high-contrast', 'true')
  } else {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('high-contrast')
  }
}

function toggleAnimations () {
  const html = document.documentElement
  const videos = document.querySelectorAll('video')

  // Toggle der Animationseinstellungen
  const animationsEnabled = !html.classList.toggle('disable-animations')

  // Video-Eigenschaften aus dem Local Storage abrufen
  // eslint-disable-next-line no-undef
  const videoSettings = JSON.parse(localStorage.getItem('videoSettings')) || { parentClasses: [] }

  videos.forEach((video, index) => {
    const parent = video.parentElement
    const hadAutoplayClassInitially = videoSettings.parentClasses[index]?.hadAutoplayClassInitially

    if (parent) {
      if (animationsEnabled) {
        // Animationen aktiv
        video.autoplay = true
        video.loop = true
        video.controls = false

        // Setze die autoplay-Klasse zurück, wenn sie zuvor existierte
        if (hadAutoplayClassInitially) {
          parent.classList.add('jonnitto-prettyembed--autoplay')
          parent.classList.remove('jonnitto-prettyembed--init', 'jonnitto-prettyembed--play')
        }

        // Manuell abspielen, wenn Autoplay aktiviert ist
        video.play().catch(error => {
          console.warn('Autoplay konnte nicht ausgeführt werden:', error)
        })
      } else {
        // Animationen deaktiviert
        video.autoplay = false
        video.loop = false
        video.controls = true

        // Entferne die autoplay-Klasse und setze die Initialisierungs-Klassen
        parent.classList.remove('jonnitto-prettyembed--autoplay')
        parent.classList.add('jonnitto-prettyembed--init', 'jonnitto-prettyembed--play')
      }
    }

    // Aktualisiere das Video, um Änderungen zu übernehmen
    video.load()
  })

  // Speichere den Status von disable-animations
  if (animationsEnabled) {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('disable-animations')
  } else {
    // eslint-disable-next-line no-undef
    localStorage.setItem('disable-animations', 'true')
  }

  // Speichere die Video-Eigenschaften im Local Storage
  // eslint-disable-next-line no-undef
  localStorage.setItem('videoSettings', JSON.stringify({
    parentClasses: Array.from(videos).map((video, index) => {
      const parent = video.parentElement
      return {
        hadAutoplayClassInitially: parent.classList.contains('jonnitto-prettyembed--autoplay')
      }
    })
  }))
}

function applyStoredSettings () {
  const html = document.documentElement // Zugriff auf den <html> Tag

  // Setze High Contrast
  // eslint-disable-next-line no-undef
  if (localStorage.getItem('high-contrast') === 'true') {
    html.classList.add('high-contrast')
    document.getElementById('highContrastButton').classList.add('active')
  }

  // Setze Large Text
  // eslint-disable-next-line no-undef
  if (localStorage.getItem('large-text') === 'true') {
    html.classList.add('large-text')
    document.getElementById('largeTextButton').classList.add('active')
  }

  // Setze disable-animations
  // eslint-disable-next-line no-undef
  const disableAnimations = localStorage.getItem('disable-animations') === 'true'
  if (disableAnimations) {
    html.classList.add('disable-animations')
    document.getElementById('playAnimationsButton').classList.add('active')
  }

  // Stelle die Video-Eigenschaften wieder her
  // eslint-disable-next-line no-undef
  const videoSettings = JSON.parse(localStorage.getItem('videoSettings')) || { parentClasses: [] }
  const videos = document.querySelectorAll('video')

  videos.forEach((video, index) => {
    const parent = video.parentElement
    const hadAutoplayClassInitially = videoSettings.parentClasses[index]?.hadAutoplayClassInitially

    if (parent) {
      // Wiederherstellen der Eigenschaften
      if (disableAnimations) {
        video.autoplay = false
        video.loop = false
        video.controls = true

        parent.classList.remove('jonnitto-prettyembed--autoplay')
        parent.classList.add('jonnitto-prettyembed--init', 'jonnitto-prettyembed--play')
      } else {
        video.autoplay = true
        video.loop = true
        video.controls = false

        // Setze die autoplay-Klasse wieder, wenn sie vorher da war
        if (hadAutoplayClassInitially) {
          parent.classList.add('jonnitto-prettyembed--autoplay')
          parent.classList.remove('jonnitto-prettyembed--init', 'jonnitto-prettyembed--play')
        }

        // Manuell abspielen, wenn Autoplay aktiviert ist
        video.play().catch(error => {
          console.warn('Autoplay konnte nicht ausgeführt werden:', error)
        })
      }
    }

    // Aktualisiere das Video, um Änderungen zu übernehmen
    video.load()
  })

  // Verstecke Bilder, falls notwendig
  // eslint-disable-next-line no-undef
  if (localStorage.getItem('hide-images') === 'true') {
    // eslint-disable-next-line no-return-assign
    document.querySelectorAll('img').forEach(img => img.style.display = 'none')
    document.getElementById('hideImagesButton').classList.add('active')
  }
}

function toggleImages () {
  const images = document.querySelectorAll('img')
  const hideImagesButton = document.getElementById('hideImagesButton')

  images.forEach(img => {
    if (img.style.display === 'none') {
      img.style.display = 'block' // Bild anzeigen
    } else {
      img.style.display = 'none' // Bild ausblenden
    }
  })

  hideImagesButton.classList.toggle('active')
  if (hideImagesButton.classList.contains('active')) {
    // eslint-disable-next-line no-undef
    localStorage.setItem('hide-images', 'true')
  } else {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('hide-images')
  }
}

// Funktion zum Anwenden gespeicherter Einstellungen

// Reset-Einstellungen zurücksetzen
function resetSettings () {
  const html = document.documentElement // Zugriff auf den <html> Tag
  html.classList.remove('high-contrast', 'large-text', 'disable-animations')
  // eslint-disable-next-line no-return-assign
  document.querySelectorAll('img').forEach(img => img.style.display = 'block')

  // Videos zurücksetzen
  const videos = document.querySelectorAll('video')
  videos.forEach(video => {
    video.autoplay = true
    video.loop = true
    video.controls = false
    video.play() // Spiele das Video ab
  })

  document.querySelectorAll('.option-button').forEach(button => button.classList.remove('active'))

  // Entferne alle gespeicherten Einstellungen
  // eslint-disable-next-line no-undef
  localStorage.clear()
}

// Menü anzeigen, wenn auf das Icon geklickt wird
document.getElementById('menuIcon').addEventListener('click', () => {
  document.getElementById('accessibilityMenu').style.display = 'block'
  document.getElementById('menuIcon').style.display = 'none'
})

// Menü schließen und das Icon wieder anzeigen
document.getElementById('closeMenu').addEventListener('click', () => {
  document.getElementById('accessibilityMenu').style.display = 'none'
  document.getElementById('menuIcon').style.display = 'block'
})

// Event-Listener für die Barrierefreiheitsfunktionen
document.getElementById('highContrastButton').addEventListener('click', () => {
  const button = document.getElementById('highContrastButton')
  button.classList.toggle('active')
  toggleContrast('high')
})

document.getElementById('largeTextButton').addEventListener('click', () => {
  const button = document.getElementById('largeTextButton')
  button.classList.toggle('active')
  toggleLargeText()
})

document.getElementById('playAnimationsButton').addEventListener('click', () => {
  const button = document.getElementById('playAnimationsButton')
  button.classList.toggle('active')
  toggleAnimations()
})

document.getElementById('hideImagesButton').addEventListener('click', () => {
  toggleImages()
})

document.getElementById('resetSettings').addEventListener('click', resetSettings)

// Anwenden gespeicherter Einstellungen beim Laden der Seite
window.addEventListener('load', applyStoredSettings)
